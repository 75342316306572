
<template>
  <v-card>
    <v-card-title>
      Cashout
    </v-card-title>
    
    <v-container>
      <v-form ref="form">
        <v-text-field
          v-model="cashout.name"
          label="Name"
          :rules="[v => !!v || 'Name is required']"
          readonly
        ></v-text-field>

        <v-text-field
          v-model="cashout.country"
          label="Country"
          :rules="[v => !!v || 'Country is required']"
          readonly
        ></v-text-field>

        <v-text-field
          v-model="cashout.idNumber"
          label="ID Number"
          :rules="[v => !!v || 'ID Number is required']"
          readonly
        ></v-text-field>

        <v-text-field
          v-model="cashout.bankName"
          label="Bank Name"
          :rules="[v => !!v || 'Bank Name is required']"
          readonly
        ></v-text-field>

        <v-text-field
          v-model="cashout.accountNumber"
          label="Account Number"
          :rules="[v => !!v || 'Account Number is required']"
          readonly
        ></v-text-field>

        <v-text-field
          v-model="cashout.currency"
          label="Currency"
          :rules="[v => !!v || 'Currency is required']"
          readonly
        ></v-text-field>

        <v-text-field
          v-model="cashout.amountInCurrency"
          label="Amount In Currency"
          :rules="[v => !!v || 'Amount is required']"
          readonly
        ></v-text-field>

        <v-select
          v-model="cashout.credited"
          label="Credited"
          :items="[ true, false ]"
        ></v-select>

        <v-text-field
          v-model="cashout.createdAt"
          label="Created At"
          :rules="[v => !!v || 'Created At is required']"
          readonly
        ></v-text-field>

        <v-text-field
          v-model="cashout.updatedAt"
          label="UpdatedAt"
          :rules="[v => !!v || 'Updated At is required']"
          readonly
        ></v-text-field>

        <v-btn color="primary" @click="update">Update</v-btn>
      </v-form>
    </v-container>
   
  </v-card>
</template>

<script>
import RestResource from "@/services/dataServiceMobile.js";

const service = new RestResource();
export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "Name", value: "name", sortable: true },
        { text: "ID Number", value: "idNumber", sortable: true },
        { text: "Country", value: "country", sortable: true },
        { text: "Currency", value: "currency", sortable: true },
        { text: "Amount", value: "amountInCurrency", sortable: false },
        { text: "Credited", value: "credited", sortable: true },
        { text: "Created At", value: "createdAt", sortable: false },
        { text: "Action", value: "actions", sortable: false },
      ],
      cashout: {}
    };
  },

  mounted() {
    this.loadCashout();
  },

  methods: {
    loadCashout() {
      this.$setLoader();
      service.fetchCashout({ _id: this.$route.params.id }).then(r => {
        this.cashout = r.data[0];
        this.$disableLoader();
      });
    },

    update() {
      if (this.$refs.form.validate()) {
        this.$setLoader();
        service.updateCashout(this.cashout).then(res => {
          this.$showSuccessMessage(res.data)
        });
      }
    }
  }
};
</script>
